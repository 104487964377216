<script>
import HorizontalMenu from '@/components/general/HorizontalMenu'
export default {
  name: 'ModalLinkedPositions',
  components: { HorizontalMenu },
  data () {
    return {
      selectedMenu: 0
    }
  },
  computed: {
    isRemovedRoute () {
      return this.$route.name.includes('removed')
    },
    menuOptions () {
      return [
        { to: { name: `business.admin.center.departments.linked.positions${this.isRemovedRoute ? '.removed' : ''}.active` }, text: 'modal.linked.positions:linked.positions.active' },
        { to: { name: `business.admin.center.departments.linked.positions${this.isRemovedRoute ? '.removed' : ''}.archived` }, text: 'modal.linked.positions:linked.positions.archived' },
        { to: { name: `business.admin.center.departments.linked.positions${this.isRemovedRoute ? '.removed' : ''}.draft` }, text: 'modal.linked.positions:linked.positions.draft' }
      ]
    }
  }
}
</script>
<template>
  <v-dialog :value="true" persistent no-click-animation width="780px" max-width="100%">
    <v-card class="modal-linked-positions--container">
      <div class="modal-linked-positions--header">
        <h2 class="modal-linked-positions--title">{{ $t('modal.linked.positions:title') }}</h2>
        <v-icon class="clickable" @click="$router.push({ name: `business.admin.center.departments.${isRemovedRoute ? 'removed' : 'active'}` })">mdi-close</v-icon>
      </div>
      <div class="modal-linked-positions--body">
        <div class="modal-linked-positions--filter">
          <horizontal-menu :options="menuOptions" v-model="selectedMenu"></horizontal-menu>
        </div>
        <router-view></router-view>
      </div>
      <div class="modal-linked-positions--bottom">
        <v-btn class="btn transform-unset" outlined :color="getPrimaryColor" dark @click="$router.push({ name: `business.admin.center.departments.${isRemovedRoute ? 'removed' : 'active'}` })">{{ $t('global:close') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-linked-positions--container {
  .modal-linked-positions--header {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: space-between;
    .modal-linked-positions--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
    }
  }
  .modal-linked-positions--body {
    text-align: left;
    padding: 24px;
    .modal-linked-positions--filter {
      margin-bottom: -44px;
      .horizontal-menu--container {
        margin: 0;
      }
    }
  }
  .modal-linked-positions--bottom {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: flex-end;
  }
 }

 @media only screen and (max-width: 640px) {
  .modal-linked-positions--container {
  .modal-linked-positions--body {
    .modal-linked-positions--filter {
      margin-bottom: 40px;
    }
  }
}
}
</style>
